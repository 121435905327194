const pictures = [
  {
    title: 'transcendentals',
    url: 'https://i.imgur.com/M7CbTqw.jpg',
  },
  {
    title: 'boot mushrooms',
    url: 'https://i.imgur.com/9FVACQB.jpg'
  },
  {
    title: 'candycap watercolor',
    url: 'https://i.imgur.com/NxLwIem.jpg'
  },
  {
    title: 'jellyfish bottle',
    url: 'https://i.imgur.com/dnzL3v9.jpg'
  },
  {
    title: 'orange swirls',
    url: 'https://i.imgur.com/9ognnhx.jpg'
  },
  {
    title: 'veins',
    url: 'https://i.imgur.com/QpvEGxx.jpg'
  },
  {
    title: 'sea turtle',
    url: 'https://i.imgur.com/f5UKxNO.jpg'
    },
  {
    title: 'rainy city',
    url: 'https://i.imgur.com/ljaRskf.jpg'
  },
  {
    title: 'sir cough syrup',
    url: 'https://i.imgur.com/O09gWBb.jpg'
  },
]

export default pictures;
